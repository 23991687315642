<template>
  <v-card class="py-2">
    <v-icon v-on:click.stop="$emit('close')" large class="closeIcon mt-3 mx-2"
      >mdi-close</v-icon
    >
    <h2
      weight="200"
      class="mt-6 d-flex font-weight-regular justify-center align-center"
    >
      {{
        this.currentUser.is_admin
          ? "Modifier un examen"
          : "Détails sur l'examen"
      }}
    </h2>
    <v-divider light color="#EF7D00" class="mt-4 mb-6 mx-4"></v-divider>
    <div class="d-flex flex-column px-10 py-4">
      <v-text-field
        v-model="exam_name"
        label="Nom de l'examen"
        prepend-icon="mdi-file-document"
        required
        :readonly="!currentUser.is_admin"
      ></v-text-field>
      <v-autocomplete
        :items="schools"
        item-text="school"
        prepend-icon="mdi-school"
        multiple
        large
        dense
        return-object
        label="École"
        v-model="exam_schools"
        :rules="school_rule()"
        :readonly="!currentUser.is_admin"
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            class="mx-1 my-1"
            @click="data.select"
            @click:close="removeSchools(data.item)"
          >
            {{ data.item }}
          </v-chip>
        </template>
      </v-autocomplete>
      <v-select
        :items="years"
        v-model="exam_year"
        label="Année"
        prepend-icon="mdi-bookmark"
        :readonly="!this.currentUser.is_admin"
        required
      ></v-select>
      <v-autocomplete
        :items="filteredMaterials"
        item-text="name"
        item-value="_id"
        prepend-icon="mdi-book"
        multiple
        large
        dense
        return-object
        label="Matières"
        v-model="exam_materials"
        :readonly="!currentUser.is_admin"
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            class="mx-1 my-1"
            @click="data.select"
            @click:close="
              currentUser.is_admin ? removeMaterials(data.item) : {}
            "
          >
            {{ data.item.name }}
          </v-chip>
        </template>
      </v-autocomplete>

      <v-menu
        v-model="date_menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
        :readonly="!currentUser.is_admin"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="formatedDate"
            label="Date de l'examen"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="exam_date"
          :first-day-of-week="1"
          @input="date_menu = false"
          locale="fr-fr"
          :readonly="!currentUser.is_admin"
        ></v-date-picker>
      </v-menu>
      <v-btn
        v-if="currentUser.is_admin"
        @click="delete_exam()"
        elevation="1"
        color="error"
        class="mx-auto"
      >
        <v-icon>mdi-delete</v-icon> Supprimer l'examen
      </v-btn>
    </div>
    <ValidateErrorButtons
      v-if="this.currentUser.is_admin"
      @successClicked="validate()"
      @errorClicked="problem()"
      :disabled="!hasBeenModified()"
    >
      <template v-slot:error>ANULER</template>
      <template v-slot:success>VALIDER</template>
    </ValidateErrorButtons>
  </v-card>
</template>

<script>
import dayjs from "dayjs";
import ValidateErrorButtons from "../components/ValidateErrorButtons.vue";
import { mapGetters } from "vuex";

export default {
  name: "DialogEditUser",
  components: {
    ValidateErrorButtons,
  },
  props: {
    exam: Object,
  },
  data: () => ({
    schools: ["ESILV", "EMLV", "IIM"],
    years: ["A1", "A2", "A3", "A4", "A5"],
    date_menu: false,

    exam_name: "",
    exam_schools: [],
    exam_year: "",
    exam_date: dayjs().format("YYYY-MM-DD"),
    exam_materials: [],
  }),
  methods: {
    problem() {
      this.$emit("close");
      this.exam_name = this.exam.name;
      this.exam_schools = this.exam.schools;
      this.exam_year = this.exam.year;
      this.exam_date = dayjs(this.exam.date).format("YYYY-MM-DD");
      this.exam_materials = this.exam.materials;
    },
    validate() {
      let payload = {
        exam_id: this.exam._id,
        name: this.exam_name,
        schools: this.exam_schools,
        year: this.exam_year,
        date: this.exam_date,
        materials: this.exam_materials.map((m) => m._id),
      };
      this.$store.dispatch("exams/patchExam", payload).then(() => {
        this.$emit("update", {
          _id: this.exam._id,
          name: this.exam_name,
          schools: this.exam_schools,
          year: this.exam_year,
          date: this.exam_date,
          materials: this.exam_materials,
        });
        this.$emit("close");
      });
    },
    checkListMaterialsAreEquals(list1, list2) {
      list1 = list1.map((m) => m._id);
      list2 = list2.map((m) => m._id);
      if (list1.length !== list2.length) return false;

      let sortedList1 = list1.slice().sort();
      let sortedList2 = list2.slice().sort();

      return sortedList1.every((value, index) => value === sortedList2[index]);
    },
    hasBeenModified() {
      return !(
        this.exam_name == this.exam.name &&
        this.exam_schools.every((e) => this.exam.schools.includes(e)) &&
        this.exam_year == this.exam.year &&
        dayjs(this.exam_date).format("YYYY-MM-DD") ==
          dayjs(this.exam.date).format("YYYY-MM-DD") &&
        this.checkListMaterialsAreEquals(
          this.exam_materials,
          this.exam.materials
        )
      );
    },
    removeMaterials(item) {
      const index = this.exam_materials
        .map((m) => {
          return m._id;
        })
        .indexOf(item._id);
      if (index >= 0) this.exam_materials.splice(index, 1);
    },
    removeSchools(item) {
      const updatedList = this.exam_schools.filter((school) => school !== item);
      this.exam_schools = [...updatedList];
    },
    delete_exam() {
      this.$store
        .dispatch("exams/deleteExam", { exam_id: this.exam._id })
        .then(() => {
          this.$emit("delete", this.exam._id);
          this.$emit("close");
        });
    },
    school_rule: function () {
      return [
        this.exam_schools.length > 0 ||
          "Au moins une année doit être sélectionnée",
      ];
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "user/getUser",
      specificUser: "admin/getSpecificUser",
      materials: "materials/getMaterials",
    }),
    filteredMaterials() {
      return this.materials.filter((material) => {
        return (
          this.exam_schools.includes(material.school) &&
          this.exam_year == material.year
        );
      });
    },
    formatedDate: function () {
      return dayjs(this.exam_date).format("DD/MM/YYYY");
    },
  },
  mounted() {
    if (this.exam) {
      this.exam_name = this.exam.name;
      this.exam_schools = this.exam.schools;
      this.exam_year = this.exam.year;
      this.exam_date = dayjs(this.exam.date).format("YYYY-MM-DD");
      this.exam_materials = JSON.parse(JSON.stringify(this.exam.materials));
    }
  },
  watch: {
    exam_schools(newExamSchools, oldExamSchools) {
      if (oldExamSchools.length != newExamSchools.length) {
        this.exam_materials = this.exam_materials.filter((material) => {
          return (
            newExamSchools.includes(material.school) &&
            this.exam_year == material.year
          );
        });
      }
    },
    exam_year(newExamYear, oldExamYear) {
      if (oldExamYear != "" && oldExamYear != newExamYear)
        this.exam_materials = [];
    },
  },
};
</script>
<style scoped>
.v-sheet {
  position: relative;
  color: #8f8f8f;
}
.v-icon,
.v-input {
  color: #8f8f8f;
}
.closeIcon {
  position: absolute;
  right: 0;
  top: 0;
}

.discord_link {
  color: inherit;
}
</style>
