<template>
  <div class="background">
    <v-container v-if="!isGameOpened" class="mb-8">
      <v-row class="mt-4">
        <v-col class="text-center" cols="12">
          <h1 class="text-h3">Informations</h1>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col cols="0" sm="1" md="1"></v-col>
        <v-col class="text-center" cols="12" sm="10" md="10">
          <v-sheet
            elevation="2"
            class="mx-4 my-4 mb-2 px-4 py-4 rounded-lg text-left"
          >
            <h2 class="text-h6">Table des matières</h2>
            <v-divider></v-divider>
            <div class="my-4">
              <ul>
                <li><a href="#qui">Qui sommes nous ?</a></li>
                <li><a href="#comment">Comment s'inscrire sur le site ?</a></li>
                <li><a href="#contact">Comment nous contacter ?</a></li>
                <li><a href="#join">Comment aider ?</a></li>
                <li><a href="#problem">Un problème avec le site ?</a></li>
                <li>
                  <a href="#stack"
                    >Comment fonctionne le site techniquement ?</a
                  >
                </li>
                <li>
                  <a href="#data"
                    >Quelles sont les données à caractère personnel stocké par
                    le site ?</a
                  >
                </li>
                <li>
                  <a href="#delete">Comment supprimer mes données du site ?</a>
                </li>
                <li>
                  <a href="#creator">Qui a créé ce ✨magnifique✨ site ?</a>
                </li>
                <li>
                  <a href="#whitehat">Soumettre une faille de sécurité 😱</a>
                </li>
              </ul>
            </div>
            <v-divider></v-divider>
            <v-row class="my-2 mx-1" id="qui">
              <v-col>
                <h2 class="text-h6">Qui sommes nous ?</h2>
                <p>
                  LéoLearning est l’association de tutorat du pôle Léonard de
                  Vinci. Nous proposons des séances de tutorat d’1h30 en petit
                  groupe. <br />
                  Tu es intéressé pour nous rejoindre en tant que tuteur ou
                  membre de notre administration ? N'hésite plus et consulte la
                  section pour <a href="#join">nous rejoindre.</a>
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="my-2 mx-1" id="comment">
              <v-col>
                <h2 class="text-h6">Comment s'inscrire sur le site ?</h2>
                <p>
                  Pour s'inscrire, il suffit de te créer un compte sur notre
                  plateforme en utilisant tes identifiants LeoID, après avoir
                  confirmé ton école et année, tu auras accès à l'emploi du
                  temps des cours disponnible ! <br />
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="my-2 mx-1" id="contact">
              <v-col>
                <h2 class="text-h6">Comment nous contacter ?</h2>
                <ul>
                  <li>
                    <a href="https://www.instagram.com/leolearningpulv/"
                      ><v-icon>mdi-instagram</v-icon>Instagram</a
                    >
                  </li>
                  <li>
                    <a href="https://www.facebook.com/LeoLearningPULV/"
                      ><v-icon>mdi-facebook</v-icon>Facebook</a
                    >
                  </li>
                  <li>
                    <a href="https://discord.gg/QrMuXZYfCj"
                      ><v-icon>mdi-discord</v-icon>Discord</a
                    >
                  </li>
                  <li>
                    <v-icon>mdi-email-outline</v-icon> Mail à
                    <a href="mailto:leolearningpulv@gmail.com"
                      >leolearningpulv@gmail.com</a
                    >
                    ou
                    <a href="mailto:leolearning@devinci.fr"
                      >leolearning@devinci.fr</a
                    >
                  </li>
                </ul>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="my-2 mx-1" id="join">
              <v-col>
                <h2 class="text-h6">Comment aider ?</h2>
                Si tu souhaites nous aider en donnant des cours c'est
                <b><a href="https://forms.gle/4Dshfwb1P1j4XWa19">ici</a></b> que
                ça se passe ! <br />
                Pour aider à l'organisation ou aider au développement du site tu
                peux nous contacter via
                <b><a href="#contact">nos réseaux sociaux</a></b>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="my-2 mx-1" id="problem">
              <v-col>
                <h2 class="text-h6">Un problème avec le site ?</h2>
                Si il s'agit d'un problème "technique" (je ne peux pas acceder à
                une partie du site ou j'ai eu un code d'erreur) contacte nous en
                fournissant:
                <ul>
                  <li>
                    Votre adresse email étudiante (avec laquelle vous vous
                    connectez)
                  </li>
                  <li>Le code d'erreur affiché s'il y en avait un</li>
                  <li>
                    Votre navigateur (Google Chrome, Microsoft Edge, Firefox,
                    Safari, ...)
                  </li>
                  <li>
                    Votre appareil (Ordinateur fixe, Ordinateur portable,
                    IPhone, Modèle smartphone, ...)
                  </li>
                  <li>L'heure approximative de l'erreur</li>
                  <li>
                    Et enfin un petit message décrivant ce que vous faisiez 🤗
                  </li>
                </ul>
                <br />
                <i
                  >Note: Contacte nous après s'être déconnecté/reconnecté et
                  avoir raffraichit la page</i
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="my-2 mx-1" id="stack">
              <v-col>
                <h2 class="text-h6">
                  Comment fonctionne le site techniquement ?
                </h2>
                Le site est composé de plusieurs modules:
                <ul>
                  <li>Front: Vue.js 2.X</li>
                  <li>Back: Node.js - Express</li>
                  <li>Matchmaking: C# - .NET 6.0</li>
                </ul>
                Si tu es intéressé pour nous aider dans le développement du
                site, contacte nous !
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="my-2 mx-1" id="data">
              <v-col>
                <h2 class="text-h6">
                  Quelles sont les données à caractère personnel stocké par le
                  site ?
                </h2>

                Le site ne connait de vous que:
                <ul>
                  <li>Email étudiant (edu.devinci.fr)</li>
                  <li>Prénom</li>
                  <li>Nom</li>
                  <li>École</li>
                  <li>Année</li>
                </ul>
                Ainsi que les données liés au site (les cours suivis/donnés). En
                vous inscrivant au site vous acceptez l'utilisation de vos
                données à des fins statistiques pour l'amélioration du service.
                <br />
                Aucunement le mot de passe que vous utilisez pour le portail du
                pôle est en notre possesion et est stocké sur le site.
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="my-2 mx-1" id="delete">
              <v-col>
                <h2 class="text-h6">Comment supprimer mes données du site ?</h2>
                Pour supprimer ton profil il faut nous contacter via un de
                <a href="#contact">nos réseaux sociaux</a>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="my-2 mx-1" id="creator">
              <v-col>
                <h2 class="text-h6">🖌️Qui a créé ce ✨magnifique✨ site ?</h2>
                Le site à été possible grâce à:
                <ul>
                  <li>Clément LAJOUX - Front/Back/Serveur/Matchmaking</li>
                  <li>Vincent DEBANDE - Back/Matchmaking</li>
                  <li>Auriane LE PERDRIEL - Front</li>
                  <li>Zoé MICHEL - Front</li>
                  <li>Yannis OBERT - Front</li>
                  <li>L'association LéoLearning 🤗</li>
                </ul>
                <h2 class="text-h6">
                  🛠️Qui maintient ce ✨magnifique✨ site ?
                </h2>
                Pierre LE BEUZ - Front/Back/Serveur
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="my-2 mx-1" id="whitehat">
              <v-col>
                <h2 class="text-h6">
                  Soumettre une faille de sécurité
                  <label @click="isGameOpened = true" style="cursor: pointer"
                    >😱</label
                  >
                </h2>
                Contacte moi directement sur Discord: pierolb
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <v-col cols="0" sm="1" md="1"></v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <Breakout @close="isGameOpened = false"></Breakout>
    </v-container>
  </div>
</template>

<script>
import Breakout from "../components/Breakout.vue";

export default {
  name: "About",

  components: {
    Breakout,
  },

  data: () => ({
    isGameOpened: false,
  }),
};
</script>

<style>
.bureau_picture {
  max-height: 300px;
}
.grey_part {
  background: #efefef;
}
.history {
  max-width: 800px;
}
</style>
