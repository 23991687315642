//import $axios from "../plugins/axios";
import Vue from "vue";

const topEndMessage = {
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Vue.swal.stopTimer);
    toast.addEventListener("mouseleave", Vue.swal.resumeTimer);
  },
};

export default {
  namespaced: true,
  state: {
    materials: [],
  },
  mutations: {
    MATERIALS(state, materials) {
      state.materials = materials;
    },
  },
  actions: {
    materials(context, parameters) {
      Vue.axios
        .get("materials/", {
          params: parameters,
        })
        .then((response) => {
          context.commit("MATERIALS", response.data.results);
        })
        .catch((err) => {
          console.log(err.response.data);
          Vue.swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Quelque chose c'est mal passé 🤔",
            footer:
              "Si le problème persiste contacte LéoLearning avec le code: 2",
          });
        });
    },
    createMaterial({ state, commit }, material) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post("materials/", material)
          .then((response) => {
            Vue.swal.mixin(topEndMessage).fire({
              icon: "success",
              title: "La matière a bien été créé !",
            });
            let arr = state.materials;
            arr.push(response.data.material);
            commit("MATERIALS", arr);
            resolve(response.data.material);
          })
          .catch((err) => {
            Vue.swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Quelque chose c'est mal passé 🤔",
              footer:
                "Si le problème persiste contacte LéoLearning avec le code: 26",
            });
            reject(err);
          });
      });
    },
    patchMaterial({ state, commit }, material) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .patch("materials/" + material._id, material)
          .then((response) => {
            Vue.swal.mixin(topEndMessage).fire({
              icon: "success",
              title: "La matière a bien été modifié !",
            });
            let arr = state.materials;
            let idx = arr.findIndex((mat) => mat._id == material._id);
            arr.splice(idx, 1, material);
            commit("MATERIALS", arr);
            resolve(response.data.material);
          })
          .catch((err) => {
            Vue.swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Quelque chose c'est mal passé 🤔",
              footer:
                "Si le problème persiste contacte LéoLearning avec le code: 27",
            });
            reject(err);
          });
      });
    },
    deleteMaterial(context, data) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .delete("materials/" + data.material_id)
          .then((response) => {
            Vue.swal.mixin(topEndMessage).fire({
              icon: "success",
              title: "La matière a bien été supprimée",
            });
            resolve(response.data.exam);
          })
          .catch((err) => {
            console.log(err.response.data);
            Vue.swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Quelque chose c'est mal passé 🤔",
              footer:
                "Si le problème persiste contacte LéoLearning avec le code: 22",
            });
            reject();
          });
      });
    },
  },
  getters: {
    getMaterials(state) {
      return state.materials;
    },
  },
};
