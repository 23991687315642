//import $axios from "../plugins/axios";
import Vue from "vue";

const topEndMessage = {
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Vue.swal.stopTimer);
    toast.addEventListener("mouseleave", Vue.swal.resumeTimer);
  },
};

export default {
  namespaced: true,
  state: {
    exams: [],
    exam: {},
  },
  mutations: {
    EXAMS(state, exams) {
      state.exams = exams;
    },
    EXAM(state, exam) {
      state.exam = exam;
    },
  },
  actions: {
    exam(context, exam) {
      context.commit("EXAM", exam);
    },
    exams(context, parameters) {
      Vue.axios
        .get("exams/", {
          params: parameters,
        })
        .then((response) => {
          context.commit("EXAMS", response.data.results);
        })
        .catch((err) => {
          console.log(err.response.data);
          Vue.swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Quelque chose c'est mal passé 🤔",
            footer:
              "Si le problème persiste contacte LéoLearning avec le code: 9",
          });
        });
    },
    createExam({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post("exams", payload)
          .then((response) => {
            let exams = state.exams;
            exams.push(response.data.exam);
            commit("EXAMS", exams);

            Vue.swal.mixin(topEndMessage).fire({
              icon: "success",
              title: "Examen créé avec succès",
            });

            resolve();
          })
          .catch((err) => {
            Vue.swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Quelque chose c'est mal passé 🤔",
              footer:
                "Si le problème persiste contacte LéoLearning avec le code: 26",
            });
            reject(err);
          });
      });
    },
    patchExam(context, data) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .patch("exams/" + data.exam_id, data)
          .then((response) => {
            // console.log(response.data);
            Vue.swal.mixin(topEndMessage).fire({
              icon: "success",
              title: "Le changement a bien été effectué",
            });
            resolve(response.data.exam);
          })
          .catch((err) => {
            console.log(err.response.data);
            Vue.swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Quelque chose c'est mal passé 🤔",
              footer:
                "Si le problème persiste contacte LéoLearning avec le code: 22",
            });
            reject();
          });
      });
    },
    deleteExam(context, data) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .delete("exams/" + data.exam_id)
          .then((response) => {
            Vue.swal.mixin(topEndMessage).fire({
              icon: "success",
              title: "L'exam a bien été supprimé",
            });
            resolve(response.data.exam);
          })
          .catch((err) => {
            console.log(err.response.data);
            Vue.swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Quelque chose c'est mal passé 🤔",
              footer:
                "Si le problème persiste contacte LéoLearning avec le code: 22",
            });
            reject();
          });
      });
    },
  },
  getters: {
    getExam(state) {
      return state.exam;
    },
    getExams(state) {
      return state.exams;
    },
  },
};
