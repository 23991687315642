<template>
  <div>
    <div class="mx-4" style="margin-bottom: -30px">
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              v-model="name"
              label="Rechercher"
              prepend-icon="mdi-magnify"
              placeholder="Rechercher"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-btn color="primary" @click="dialog_create_material = true">
              <v-icon class="mr-1 ml-n1">mdi-plus</v-icon> Créer une
              matière</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col cols="auto">Filtres : </v-col>
          <v-col>
            <v-autocomplete
              :items="schools"
              prepend-icon="mdi-school"
              multiple
              large
              dense
              label="École"
              v-model="filteredSchools"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  class="mx-1 my-1"
                  @click="data.select"
                  @click:close="removeSchool(data.item)"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="years"
              prepend-icon="mdi-cake"
              multiple
              large
              dense
              label="Année"
              v-model="filteredYears"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  class="mx-1 my-1"
                  @click="data.select"
                  @click:close="removeYear(data.item)"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="semesters"
              prepend-icon="mdi-calendar"
              multiple
              large
              dense
              label="Semestres"
              v-model="filteredSemesters"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  class="mx-1 my-1"
                  @click="data.select"
                  @click:close="removeSemester(data.item)"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="dialog_create_material" width="600">
        <DialogMaterial
          :src_material="{}"
          :create="true"
          :_id="''"
          @close="dialog_create_material = false"
        />
      </v-dialog>
    </div>
    <div class="overflow-y-auto my-0 pa-0" style="height: 480px">
      <CardMaterial
        class="mx-0 my-0"
        v-for="material in filteredMaterial"
        :key="material._id"
        :material="material"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardMaterial from "./CardMaterial.vue";
import DialogMaterial from "./DialogMaterial.vue";

export default {
  name: "MaterialViewer",
  components: {
    CardMaterial,
    DialogMaterial,
  },
  data() {
    const years = ["A1", "A2", "A3", "A4", "A5"];
    const semesters = ["S1", "S2"];
    const schools = ["ESILV", "EMLV", "IIM"];
    return {
      scrollInvoked: 0,
      menu: false,

      years: [...years],
      semesters: [...semesters],
      schools: [...schools],

      filteredYears: [...years],
      filteredSemesters: [...semesters],
      filteredSchools: [...schools],

      name: "",

      dialog_create_material: false,

      date: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      allMaterials: "materials/getMaterials",
    }),
    filteredMaterial: function () {
      let res = this.allMaterials;
      res = res.filter((material) => {
        return this.complyWithFilter(material);
      });
      return res;
    },
  },
  methods: {
    removeSchool(item) {
      const index = this.filteredSchools.indexOf(item);
      if (index >= 0) this.filteredSchools.splice(index, 1);
    },
    removeYear(item) {
      const index = this.filteredYears.indexOf(item);
      if (index >= 0) this.filteredYears.splice(index, 1);
    },
    removeSemester(item) {
      const index = this.filteredSemesters.indexOf(item);
      if (index >= 0) this.filteredSemesters.splice(index, 1);
    },
    onScroll() {
      this.scrollInvoked++;
    },
    redirectMaterial(id) {
      this.$router.push("/modifycourse/" + id);
    },
    complyWithFilter(material) {
      //Name filter
      if (this.name != null && this.name != "") {
        let materialName = material.name.toLowerCase();
        if (!materialName.includes(this.name.toLowerCase())) {
          return false;
        }
      }

      //School filter
      if (!this.filteredSchools.includes(material.school)) {
        return false;
      }

      //Year filter
      if (!this.filteredYears.includes(material.year)) {
        return false;
      }

      //Semester filter
      if (!this.filteredSemesters.includes(material.semester)) {
        return false;
      }

      return true;
    },
  },
  beforeMount: function () {
    this.$store.dispatch("materials/materials", {
      limit: 10000,
      page: 1,
      is_displayed: "all",
    });
  },
};
</script>
