var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.user.is_teacher &&
      _vm.showTeacher &&
      !_vm.showStudent &&
      _vm.user.teacher.courses.length == 0 &&
      _vm.cards.length != 0
    )?_c('v-alert',{staticClass:"mx-4 my-4",attrs:{"type":"info"}},[_vm._v("Vous n'avez proposé aucun cours")]):(_vm.cards.length == 0)?_c('div',[(_vm.user.is_teacher)?_c('v-alert',{staticClass:"mx-4 my-4",attrs:{"type":"info"}},[_vm._v("Vous n'êtes inscrit a aucun cours et n'avez proposé aucun cours")]):_c('v-alert',{staticClass:"mx-4 my-4",attrs:{"type":"info"}},[_vm._v("Vous n'êtes inscrit a aucun cours")])],1):_c('div',_vm._l((_vm.filteredCard),function(card){return _c('div',{key:card._id},[(card.type == 'application')?_c('CardApplication',{staticClass:"mx-3 my-3",attrs:{"application":card,"adminMod":_vm.adminMod,"modifyCourse":false,"course_status":card.course.status}}):(card.type == 'course')?_c('CardCourse',{staticClass:"mx-3 my-3",attrs:{"course":card,"isAdminMode":_vm.adminMod}}):_vm._e()],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }