<template>
  <v-card class="py-2">
    <v-icon v-on:click.stop="$emit('close')" large class="closeIcon mt-3 mx-2"
      >mdi-close</v-icon
    >
    <h2
      weight="200"
      class="mt-6 d-flex font-weight-regular justify-center align-center"
    >
      Ajouter un examen
    </h2>
    <v-divider light color="#EF7D00" class="mt-4 mb-6 mx-4"></v-divider>
    <div class="d-flex flex-column px-10 py-4">
      <v-text-field
        v-model="exam_name"
        label="Nom de l'examen"
        prepend-icon="mdi-file-document"
        required
      ></v-text-field>
      <v-autocomplete
        :items="schools"
        item-text="school"
        prepend-icon="mdi-school"
        multiple
        large
        dense
        return-object
        label="École"
        v-model="exam_schools"
        :rules="school_rule()"
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            class="mx-1 my-1"
            @click="data.select"
            @click:close="removeSchools(data.item)"
          >
            {{ data.item }}
          </v-chip>
        </template>
      </v-autocomplete>
      <v-select
        :items="years"
        v-model="exam_year"
        label="Année"
        prepend-icon="mdi-bookmark"
        required
      ></v-select>
      <v-autocomplete
        v-if="exam_schools.length > 0 && exam_year"
        :items="filteredMaterials"
        item-text="name"
        item-value="_id"
        prepend-icon="mdi-book"
        multiple
        large
        dense
        return-object
        label="Matières"
        v-model="exam_materials"
        :rules="material_rule()"
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            class="mx-1 my-1"
            @click="data.select"
            @click:close="removeMaterials(data.item)"
          >
            {{ data.item.name }}
          </v-chip>
        </template>
      </v-autocomplete>
      <v-menu
        v-model="date_menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="formatedDate"
            label="Date de l'examen"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="exam_date"
          :first-day-of-week="1"
          @input="date_menu = false"
          locale="fr-fr"
        ></v-date-picker>
      </v-menu>
    </div>
    <ValidateErrorButtons
      @successClicked="validate()"
      @errorClicked="problem()"
      :disabled="exam_name == '' || exam_year == ''"
    >
      <template v-slot:error>ANULER</template>
      <template v-slot:success>VALIDER</template>
    </ValidateErrorButtons>
  </v-card>
</template>

<script>
import dayjs from "dayjs";
import ValidateErrorButtons from "../components/ValidateErrorButtons.vue";
import { mapGetters } from "vuex";

export default {
  name: "DialogEditUser",
  components: {
    ValidateErrorButtons,
  },
  props: {
    user: Object,
    adminMod: Boolean,
  },
  data: () => ({
    schools: ["ESILV", "EMLV", "IIM"],
    years: ["A1", "A2", "A3", "A4", "A5"],
    date_menu: false,

    exam_name: "",
    exam_schools: [],
    exam_year: "",
    exam_date: dayjs().format("YYYY-MM-DD"),
    exam_materials: [],
  }),
  methods: {
    problem: function () {
      this.$emit("close");
    },
    validate: function () {
      let payload = {
        name: this.exam_name,
        schools: this.exam_schools,
        year: this.exam_year,
        date: this.exam_date,
        materials: this.exam_materials.map((m) => m._id),
      };
      this.$store.dispatch("exams/createExam", payload).then(() => {
        this.$emit("close");
      });
    },
    removeSchools(item) {
      const index = this.exam_schools.indexOf(item);
      if (index >= 0) this.exam_schools.splice(index, 1);
    },
    removeMaterials(item) {
      const index = this.exam_materials
        .map((m) => {
          return m._id;
        })
        .indexOf(item._id);
      if (index >= 0) this.exam_materials.splice(index, 1);
    },
    school_rule: function () {
      return [
        this.exam_schools.length > 0 ||
          "Au moins une année doit être sélectionnée",
      ];
    },
    material_rule: function () {
      return [
        this.exam_materials.length > 0 ||
          "Au moins une des matières doit être sélectionnée",
      ];
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "user/getUser",
      specificUser: "admin/getSpecificUser",
      materials: "materials/getMaterials",
    }),
    filteredMaterials() {
      return this.materials.filter((material) => {
        return (
          this.exam_schools.includes(material.school) &&
          this.exam_year == material.year
        );
      });
    },
    formatedDate: function () {
      return dayjs(this.exam_date).format("DD/MM/YYYY");
    },
  },
};
</script>
<style scoped>
.v-sheet {
  position: relative;
  color: #8f8f8f;
}
.v-icon,
.v-input {
  color: #8f8f8f;
}
.closeIcon {
  position: absolute;
  right: 0;
  top: 0;
}

.discord_link {
  color: inherit;
}
</style>
